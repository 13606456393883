import React, { useState, useEffect } from 'react';
import './Cadastro.css';
import Modal from 'react-modal';
import Logo from './logo.jpeg';
import Backup from './Backup';
import { createRoot } from 'react-dom';
import Login from './Login';
import LoginSU from './LoginSU';

function cadastro(event) {
  event.preventDefault();
  const newName = document.getElementById('name').value;
  const newUserName = document.getElementById('username').value;
  const newPassword = document.getElementById('password').value;
  const MatchPassword = document.getElementById('Repeatpassword').value;
  let su = 0 ;
  
  if (
    newName.trim().length !== 0 &&
    newUserName.trim().length !== 0 &&
    newPassword.trim().length !== 0 &&
    MatchPassword.trim().length !== 0  
  ) 
  {
  if (newPassword === MatchPassword) 
  {
    if(document.getElementById("su").checked)
    {
      su = 1;
    }
    else{
      su = 0;
    }
  fetch('http://170.238.104.218:3333/postDB', {
  method: 'POST',
  headers: {
  'Content-Type': 'application/json'
  },
  body: JSON.stringify({ newName, newUserName, newPassword, su })
  })
  .then(response => response.json())
  .then(data => 
    {
     
      alert(data);
          
      const root = createRoot(document.getElementById('root'));
      root.render(<Cadastro />);
 
    })
    
  .catch(error => alert(error.message));
  }
   else 
  {
  alert('As senhas não conferem ou estão em branco.');
  }
  }
  else{
  alert('Nenhum campo pode ficar em branco');

  }
  
}

function menu()
{
  const root = createRoot(document.getElementById('root'));
  root.render(<LoginSU />);
}

function Cadastro() {
  const [showModal, setShowModal] = useState(false);

  return (

    <div className="body">
      <div className="container">

        <div className="wrap">
                
          <form className="form">
            <span className="title">Faça o Cadastro</span>

        
            <div className="wrap-input">
              <input className="Name" type="text"  name="name" id="name" required placeholder="Seu Nome"/>
              <span className="focus-input"></span>
            </div>

             
            <div className="wrap-input">


              <input className="inputName" type="text" name="username" id="username" required placeholder="Nome de usuário" onInput={(e) => e.target.value = e.target.value.toLowerCase()} />
              <span className="focus-input"></span>
            </div>

             
            <div className="wrap-input">
              <input className="inputPassword" type="password" name="password" id="password" required placeholder="Sua senha" />
              <span className="focus-input"></span>
            </div>


               
            <div className="wrap-input">
              <input className="RepeatinputPassword" type="password" name="Repeatpassword" id="Repeatpassword" required placeholder="Repita a senha" />
              <span className="focus-input"></span>
            </div>
              <br></br>
              <div className="su" > Administrador
            <input id='su' type="checkbox" className='box'/>
             </div>
              <br></br>

            <div className="btn">
              <button onClick=
              {
                cadastro
                
              } className="btn-btn">Realizar cadastro</button>
            </div>

                <div className='info'>
                </div>
            <div className="btn">
              <button onClick=
              {
                menu  
            
              } className="btn-btn">Sair</button>
            </div>
 


 
          </form>
          <div className="image" style={{backgroundImage: `(${Logo})`}}></div>
        </div>
      </div>
    </div>
    
  );
}

export default Cadastro;
