import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom';
import Backup from './Backup.js';
import './FileList.css'
import './FileList.js'


function ToMenu() {
  const root = createRoot(document.getElementById("root"));
  root.render(<Backup />);
}

// AQUI NÓS IREMOS FAZER O DOWNLOAD DE ARQUIO

function downloadFile(fileName) {
  const username = sessionStorage.getItem('user');

  const headers = new Headers();
  headers.append('username', username);

  const xhr = new XMLHttpRequest();

  xhr.open('GET', `http://170.238.104.218:3333/download/${fileName}`);

  xhr.setRequestHeader('username', username);
  xhr.responseType = 'blob';

  const progressContainer = document.createElement('div');
  progressContainer.classList.add('progress-container');

  const progressPanel = document.createElement('div');
  progressPanel.classList.add('progress-panel');

  const progressBar = document.createElement('progress');
  progressPanel.appendChild(progressBar);

  const statusText = document.createElement('p');
  statusText.classList.add('progress-text');
  progressPanel.appendChild(statusText);

  progressContainer.appendChild(progressPanel);

  document.body.appendChild(progressContainer);

  xhr.addEventListener('progress', (event) => {
    const progress = event.loaded / event.total * 100;
    console.log(`Progresso do download: ${progress}%`);
    progressBar.value = progress / 100;
    statusText.innerText = `Baixando: ${fileName} (${progress.toFixed(2)}%)`;
  });

  xhr.addEventListener('load', () => {
    if (xhr.status === 200) {
      const url = window.URL.createObjectURL(xhr.response);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Erro ao baixar o arquivo.');
    }
    progressContainer.remove();
  });

  xhr.addEventListener('error', (error) => {
    console.error(error);
    progressContainer.remove();
  });

  xhr.addEventListener('abort', () => {
    progressContainer.remove();
  });

  xhr.send();
}

// AQUI USAREMOS A FUNÇÃO PARA DELETAR O ARQUIVO

function deleteFile(filename) {
  const username = sessionStorage.getItem('user');
  const root = createRoot(document.getElementById("root"));

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  fetch(`http://170.238.104.218:3333/posts/${username}/${filename}`, {
    method: 'DELETE',
    headers: headers
  })
    .then(response => {
      if (response.ok) {
        alert('Arquivo excluído com sucesso.');
        // COLOCAR UM UPDATE DA PAGINA, PARA MOSTRAR QUE O ARQUIVO FOI ECLUÍDO
        root.render(<FileList />);
      } else {
        //ESSE ERRO ESTÁ SENDO CAUSADO DEVIDO A PAGINA NÃO ESTAR SENDO ATUALIZADA
        alert('Erro ao excluir o arquivo.');
      }
    })
    .catch(error => {
      console.error(error);
    });
}




function FileList() {
  const [files, setFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  async function fetchFiles() {

    const username = sessionStorage.getItem('user');
    const response = await fetch('http://170.238.104.218:3333/view', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'username': username

      }

    });

    const data = await response.json();
    setFiles(data);
  }

  useEffect(() => {
    fetchFiles();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [searchTerm, setSearchTerm] = useState('');



  const filteredItens = files.filter(filename =>
    filename.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentItems = filteredItens.slice(indexOfFirstItem, indexOfLastItem);


  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const renderItems = currentItems.map((file, index) => (

    
    <div className='division'>

 <div className='user-box'>  

<div className='div1'>
  <p>Nome: <span>{file.name}</span></p>

</div>

<div className='div2'>
  
  <button className='edit-button' onClick={() => downloadFile(file.name)} key={index}>
    Baixar
  </button>

  <br></br>

  <button className='delete-button' onClick={() => deleteFile(file.name)} key={index}>
    excluir
  </button>
</div>

</div>
     

    
</div>
  ));

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(files.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map(number => (
    <button
      className="page-link"
      key={number}
      onClick={() => setCurrentPage(number)}
    >
      {number}
    </button>
  ));

  return (
    <div className="main">
      <h1 className='title'>Arquivos
        <div className="searchInput ">
          <input
            className="searchBox"
            type="text"
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
        </div>
      </h1>
      <div className='container'>
        {renderItems}
      </div>
      <div className="pagination-container">
        <div className="pagination">{renderPageNumbers}</div>
      </div>
      <button className='back' onClick={()=> ToMenu()}>
        Voltar
      </button>
    </div>
  );
}
export default FileList;