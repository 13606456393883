import React, { useState } from 'react';
import Modal from 'react-modal';
import './Login.css';
import Logo from './logo.jpeg';
import Backup from './Backup';
import { createRoot } from 'react-dom';
import Cadastro from './Cadastro';
import LoginSU from './LoginSU';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function login(event) {
  const root = createRoot(document.getElementById('root'));
  event.preventDefault();
  const username = document.getElementById('username').value;
  const password = document.getElementById('password').value;

  // Alterne entre localhost e servidor remoto com base na disponibilidade da internet
  const serverURL = navigator.onLine
    ? 'https://170.238.104.218:3333/users'
    : 'http://localhost:3333/users';

  fetch(serverURL)
    .then(response => response.json())
    .then(data => {
      const user = data.find(
        user =>
          user.username.toLowerCase() === username.toLowerCase() &&
          user.senha === password
      );

      if (user) {
        if (user.su) {
          sessionStorage.setItem('user', user.username);
          const su = user.su;
          if (su === 1) {
            root.render(<LoginSU />);
          }
        } else {
          sessionStorage.setItem('user', user.username);
          root.render(<Backup />);
        }
      } else {
        const button = document.getElementById('login-button');
        button.classList.add('disabled', 'button-move');
        button.style.pointerEvents = 'none';
        button.style.cursor = 'not-allowed';

        setTimeout(() => {
          button.classList.remove('button-move');
        }, 500);
      }
    })
    .catch(error => {
      console.error(error);
      alert('Ocorreu um erro ao buscar os dados do usuário.' + error);
    });
}

function Login() {
  const [showModal, setShowModal] = useState(true);

  return (
    <div className="body">
      <div className="container">
        <div className="wrap">
          <form className="form">
            <span className="title">Conecte-se</span>
            <div className="wrap-input">
              <input
                className="inputName"
                type="text"
                name="username"
                id="username"
                required
                placeholder="Usuário"
              />
              <span className="focus-input"></span>
            </div>

            <div className="wrap-input">
              <input
                className="inputPassword"
                type="password"
                name="password"
                id="password"
                required
                placeholder="Senha"
              />
              <span className="focus-input"></span>
            </div>

            <div className="flex">
              <a href="#" className="txt1">
                Esqueci a Senha
              </a>
            </div>

            <div className="btn">
              <button onClick={login} className="btn-btn">
                Entrar
              </button>
            </div>
            <br />
            <br />
            <div className="copyright">
              <p className="copyright-text"><span className="copyright-text-owner">&copy; BITTS Tecnologia 2023</span>. Desenvolvido por <a className="copy" href="#" target={'_blank'}>Gilmar Prado</a>. Todos os direitos reservados. </p>
            </div>
          </form>

          <div
            className="image"
            style={{ backgroundImage: `(${Logo})` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Login
